import api from '@/utils/ajax'

export default {
  namespaced: true,

  state: {
    classifyList: [],
    productList: []
  },

  mutations: {
    setClassifyList(state, classifyList) {
      state.classifyList = classifyList
    },
    setProductList(state, productList) {
      state.productList = productList
    }
  },

  getters: {},

  actions: {
    /**
     * 登录
     * @param {*} ctx
     * @param {String} username 用户名
     * @param {String} password 密码
     */
    async login(ctx, { username, password } = {}) {
      const data = await api.post('/api/admin/user/login', {
        data: {
          username,
          password
        }
      })
      const userInfo = {
        token: data
      }
      sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
      return data
    },

    /**
     * 上传图片
     * @param {*} ctx
     * @param {String} file 文件
     */
    async uploadImg(ctx, file) {
      const data = await api.formdataPost('/upload/uploadfile', {
        data: {
          file: file
        }
      })
      return data
    },

    /**
     * 获取分类列表
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     */
    async classifyList(ctx, { page, size } = {}) {
      const data = await api.post('/api/admin/classify/get', {
        data: {
          page,
          size
        }
      })
      ctx.commit('setClassifyList', data.list)
      return data
    },

    /**
     * 编辑分类
     * @param {*} ctx
     * @param {String} id id
     * @param {String} name 名称
     * @param {String} sort 排序
     */
    async updateClassify(ctx, { id, name, sort } = {}) {
      let data
      if (!id) {
        data = await api.post('/api/admin/classify/add', {
          data: {
            name,
            sort
          }
        })
      } else {
        data = await api.post('/api/admin/classify/update', {
          data: {
            id,
            name,
            sort
          }
        })
      }
      return data
    },

    /**
     * 删除分类
     * @param {*} ctx
     * @param {*} id id
     */
    async deleteClassify(ctx, { id } = {}) {
      return await api.post('/api/admin/classify/delete', {
        data: {
          id
        }
      })
    },

    /**
     * 获取商品列表
     * @param {Number} page 页码
     * @param {Number} size 每页条数
     * @param {String} classifyId 分类id
     * @param {String} keyword 关键词
     */
    async productList(ctx, { page, size, classifyId, keyword } = {}) {
      const data = await api.post('/api/admin/product/get', {
        data: {
          page,
          size,
          classifyId,
          keyword
        }
      })
      ctx.commit('setProductList', data.list)
      return data
    },

    /**
     * 获取商品
     * @param {*} ctx
     * @param {*} id id
     */
    async getProductDetail(ctx, { id } = {}) {
      return await api.post('/api/admin/product/getDetail', {
        data: {
          id: id
        }
      })
    },

    /**
     * 编辑商品
     * @param {*} ctx
     * @param {String} id id
     * @param {String} name 商品名称
     * @param {String} price 价格
     * @param {String} classify_id 分类
     * @param {String} banner 轮播
     * @param {String} desc 详情
     */
    async updateProduct(ctx, { id, name, price, classifyId, banner, desc } = {}) {
      let data
      if (!id) {
        data = await api.post('/api/admin/product/add', {
          data: {
            name,
            price,
            classify_id: classifyId,
            banner,
            desc
          }
        })
      } else {
        data = await api.post('/api/admin/product/update', {
          data: {
            id,
            name,
            price,
            classify_id: classifyId,
            banner,
            desc
          }
        })
      }
      return data
    },

    /**
     * 删除商品
     * @param {*} ctx
     * @param {String} id id
     */
    async deleteProduct(ctx, { id } = {}) {
      return await api.post('/api/admin/product/delete?id=' + id)
    }
  }
}
