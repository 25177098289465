import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/classify'
  },
  {
    name: '分类管理',
    path: '/classify',
    component: () => import('../views/shopping/classify'),
    meta: {
      title: '分类管理',
      subtitle: '管理商品的分类'
    }
  },
  {
    name: '商品管理',
    path: '/product',
    component: () => import('../views/shopping/product'),
    meta: {
      title: '商品管理',
      subtitle: '管理商品的信息'
    }
  },
  {
    name: '商品详情',
    path: '/productDetail',
    component: () => import('../views/shopping/product-detail'),
    meta: {
      title: '商品详情',
      subtitle: '商品详情信息'
    }
  },
]

const customRouter = [
]

function _extends(r) {
  r.forEach(item => {
    routes.push(item)
  })
}

customRouter.forEach(item => {
  _extends(item)
})

const router = new VueRouter({
  routes
})

export default router
